export function menuPaths(location) {
  const paths = [
    '/',
    '/signin',
    '/automated',
    '/about-us',
    '/experts',
    '/boxettes',
    '/signin/',
    '/about-us/',
    '/experts/',
    '/boxettes/',
    '/tech-info',
    '/labox-news',
    '/subscriptions',
    '/issuers',
    '/admin',
    '/tips',
    '/menu-contact',
    '/menu-account',
  ];
  return paths.includes(location.pathname);
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useIntervalAsync = (fn, ms, useRef, useCallback, useEffect) => {
  const timeout = useRef();
  const mountedRef = useRef(false);

  const run = useCallback(async () => {
    await fn();
    if (mountedRef.current) {
      timeout.current = window.setTimeout(run, ms);
    }
  }, [fn, ms]);

  useEffect(() => {
    mountedRef.current = true;
    run();
    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [run]);
};

export const delay = (ms) => new Promise(
  // eslint-disable-next-line no-promise-executor-return
  (resolve) => setTimeout(resolve, ms),
);

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import alcoconvimage from 'files/conversion-alco-vol.jpg';
import volconvimage from 'files/barrel.jpg';
import massvolconvimage from 'files/conversion-alco-mass.jpg';
import ingredientAdjustmentsImage from 'files/ingredient_adjustments.jpg';
import ebulliometerImage from 'files/ebulliometre_electronique.jpg';
import mutageImage from 'files/mutage.jpg';
import dryExtractObscurationImage from 'files/dryExtractObscuration.jpg';
import ullagingimage from 'files/barrel_ullaging.jpg';
import alcotousconvimage from 'files/alco-to-us-conv.jpg';
import energecticValueImage from 'files/energeticValue.jpg';
import cognacImage from 'files/cognac.jpg';
import flashPointImage from 'files/flashpoint.jpg';
import unitConversions from 'files/unit-conversions.jpeg';
import { useSigninCheck } from 'reactfire';
import AlcoConversionBoxette from 'components/boxettes/alco-conversion/AlcoConversionBoxette';
import VolumeConversionBoxette from 'components/boxettes/vol-conversion/VolumeConversionBoxette';
import VolumePAConversionBoxette from 'components/boxettes/vol-pa-conversion/VolumePAConversionBoxette';
import VolumeConversionAlcoBoxette from 'components/boxettes/vol-conversion/VolumeConversionAlcoBoxette';
import MassVolumeAlcoBoxette from 'components/boxettes/mass-vol-conversion/MassVolumeAlcoBoxette';
import MassVolumeBoxette from 'components/boxettes/mass-vol-conversion/MassVolumeBoxette';
import ReductionImprovementBoxette from 'components/boxettes/reduction-improvement/ReductionImprovementBoxette';
import DryExtractBoxette from 'components/boxettes/dryExtractObscuration/DryExtractBoxette';
import VolumePAWithDryExtractBoxette from 'components/boxettes/vol-pa-with-dry-extract/VolumePAWithDryExtractBoxette';
import UllagingBoxette from 'components/boxettes/ullaging/Ullaging';
import IngredientAdjustmentsBoxette from 'components/boxettes/ingredientAdjustments/IngredientAdjustmentsBoxette';
import EbulliometerBoxette from 'components/boxettes/ebulliometer/EbulliometerBoxette';
import {
  Routes, Route, Navigate, Link,
} from 'react-router-dom';
import BoxettesApiCalls from 'components/boxettes/BoxettesApiCalls';
import Box from '@mui/material/Box';
import AlcoConversionToUSBoxette from 'components/boxettes/alco-to-us-conversion/AlcoConversionToUSBoxette';
import OIMLConformity from 'components/OIMLConformity';
import VerifyEmail from 'components/account/VerifyEmail';
import EnergeticValueBoxette from 'components/boxettes/energeticValue/EnergeticValueBoxette';
import FlashPointBoxette from 'components/boxettes/flash-point/FlashPointBoxette';
import useMenu from 'menu-actions/useMenu';
import ActiveSO2Boxette from 'components/boxettes/so2-conversions/ActiveSO2Boxette';
import ActiveSO2Rectify from 'components/boxettes/so2-conversions/ActiveSO2Rectify';
import FreeSO2Boxette from 'components/boxettes/so2-conversions/FreeSO2Boxette';
import so2Image from 'files/so2Image.jpg';
import so2LibreImage from 'files/so2libre.jpg';
import useGlobal from 'global-state/store';
import { Button } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BoxetteCard from './BoxetteCard';
import FavoriteBoxettes from './FavoriteBoxettes';
import MutageBoxette from './mutage/MutageBoxette';
import UnitConversions from './unit-conversions/UnitConversions';

export default function BoxettesMenu() {
  const { dispatchMenuActions } = useMenu();
  const [globalState] = useGlobal();
  const { t } = useTranslation();

  const cleanupMenuActions = useCallback(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  useEffect(() => () => cleanupMenuActions(), [cleanupMenuActions]);

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = () => requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));

  return (
    <Routes>
      <Route
        path="alco-conv"
        element={<BoxettesApiCalls boxette={<AlcoConversionBoxette />} />}
      />
      <Route
        path="vol-conv"
        element={<BoxettesApiCalls boxette={<VolumeConversionBoxette />} />}
      />
      <Route
        path="vol-pa-conv"
        element={<BoxettesApiCalls boxette={<VolumePAConversionBoxette />} />}
      />
      <Route
        path="vol-alco-conv"
        element={<BoxettesApiCalls boxette={<VolumeConversionAlcoBoxette />} />}
      />
      <Route
        path="reduction-improvement"
        element={<BoxettesApiCalls boxette={<ReductionImprovementBoxette />} />}
      />
      <Route
        path="ebulliometer"
        element={<BoxettesApiCalls boxette={<EbulliometerBoxette />} />}
      />
      <Route
        path="unit-conversions"
        element={<BoxettesApiCalls boxette={<UnitConversions />} />}
      />

      {hasRequiredGrants() ? (
        <>
          <Route
            path="ullaging"
            element={<BoxettesApiCalls boxette={<UllagingBoxette />} />}
          />
          <Route
            path="mass-vol-alco-conv"
            element={<BoxettesApiCalls boxette={<MassVolumeAlcoBoxette />} />}
          />
          <Route
            path="mass-vol-conv"
            element={<BoxettesApiCalls boxette={<MassVolumeBoxette />} />}
          />
          <Route
            path="energetic-value"
            element={<BoxettesApiCalls boxette={<EnergeticValueBoxette />} />}
          />
          <Route
            path="mutage"
            element={<BoxettesApiCalls boxette={<MutageBoxette />} />}
          />
          <Route
            path="pa-conv-with-dry-extract"
            element={<BoxettesApiCalls boxette={<VolumePAWithDryExtractBoxette />} />}
          />
          <Route
            path="alco-to-us-conv"
            element={<BoxettesApiCalls boxette={<AlcoConversionToUSBoxette />} />}
          />
          <Route
            path="ingredient-adjustments"
            element={<BoxettesApiCalls boxette={<IngredientAdjustmentsBoxette />} />}
          />
          <Route
            path="dryExtractObscuration"
            element={<BoxettesApiCalls boxette={<DryExtractBoxette />} />}
          />
          <Route
            path="flash-point"
            element={<BoxettesApiCalls boxette={<FlashPointBoxette />} />}
          />
          <Route
            path="active-so2"
            element={<BoxettesApiCalls boxette={<ActiveSO2Boxette />} />}
          />
          <Route
            path="active-so2/rectify"
            element={<BoxettesApiCalls boxette={<ActiveSO2Rectify />} />}
          />
          <Route
            path="free-so2"
            element={<BoxettesApiCalls boxette={<FreeSO2Boxette />} />}
          />
        </>
      ) : (
        <Route path="*" element={<Navigate to="/subscriptions" replace />} />
      )}
      <Route path="" element={<Boxettes />} />
      <Route
        path="*"
        element={(
          <main style={{ padding: '1rem' }}>
            <p>{t('nothing_here')}</p>
          </main>
        )}
      />
    </Routes>
  );
}

function Boxettes(props) {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const { dispatchMenuActions } = useMenu();
  const [globalState] = useGlobal();

  const cleanupMenuActions = useCallback(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  const cleanupMenuActionsAndCurrentForm = useCallback(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes('current_form:')) {
        sessionStorage.removeItem(key);
      }
    });
    cleanupMenuActions();
  }, [cleanupMenuActions]);

  useEffect(() => {
    cleanupMenuActionsAndCurrentForm();
  }, [cleanupMenuActionsAndCurrentForm]);

  const initialBoxettes = () => {
    const savedFavsLocal = localStorage.getItem('favorite_boxettes');
    const savedFavs = savedFavsLocal === null ? [] : JSON.parse(savedFavsLocal);
    return {
      'alco-conv': {
        route: 'alco-conv',
        title: t('boxettes.alco_conversion'),
        image: alcoconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('alco-conv'),
        free: true,
      },
      'vol-conv': {
        route: 'vol-conv',
        title: t('boxettes.vol_conversion'),
        image: volconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('vol-conv'),
        free: true,
      },
      ullaging: {
        route: 'ullaging',
        title: t('boxettes.ullaging'),
        image: ullagingimage,
        category: 'production',
        favorite: savedFavs.includes('ullaging'),
        free: false,
      },
      'mass-vol-conv': {
        route: 'mass-vol-conv',
        title: t('boxettes.mass_vol_conversion'),
        image: massvolconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('mass-vol-conv'),
        free: false,
      },
      'alco-to-us-conv': {
        route: 'alco-to-us-conv',
        title: t('boxettes.alco_us_conversion'),
        image: alcotousconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('alco-to-us-conv'),
        free: false,
      },
      dryExtractObscuration: {
        route: 'dryExtractObscuration',
        title: t('boxettes.dryExtractObscuration'),
        image: dryExtractObscurationImage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('dryExtractObscuration'),
        free: false,
      },
      ebulliometer: {
        route: 'ebulliometer',
        title: t('boxettes.ebulliometer'),
        image: ebulliometerImage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('ebulliometer'),
        free: true,
      },
      'reduction-improvement': {
        route: 'reduction-improvement',
        title: t('boxettes.reduction_improvement'),
        image: cognacImage,
        category: 'production',
        favorite: savedFavs.includes('reduction-improvement'),
        free: true,
      },
      'unit-conversions': {
        route: 'unit-conversions',
        title: t('boxettes.unit_conversions'),
        image: unitConversions,
        category: 'production',
        favorite: savedFavs.includes('unit-conversions'),
        free: true,
      },
      'ingredient-adjustments': {
        route: 'ingredient-adjustments',
        title: t('boxettes.ingredient_adjustments'),
        image: ingredientAdjustmentsImage,
        category: 'production',
        favorite: savedFavs.includes('ingredient-adjustments'),
        free: false,
      },
      'energetic-value': {
        route: 'energetic-value',
        title: t('boxettes.energetic_value'),
        image: energecticValueImage,
        category: 'production',
        favorite: savedFavs.includes('energetic-value'),
        free: false,
      },
      'flash-point': {
        route: 'flash-point',
        title: t('boxettes.flash_point'),
        image: flashPointImage,
        category: 'production',
        favorite: savedFavs.includes('flash-point'),
        free: false,
      },
      'active-so2': {
        route: 'active-so2',
        title: t('boxettes.active_so2'),
        image: so2Image,
        category: 'production',
        favorite: savedFavs.includes('active-so2'),
        free: false,
      },
      'free-so2': {
        route: 'free-so2',
        title: t('boxettes.free_so2'),
        image: so2LibreImage,
        category: 'production',
        favorite: savedFavs.includes('free-so2'),
        free: false,
      },
      mutage: {
        route: 'mutage',
        title: t('boxettes.mutage'),
        image: mutageImage,
        category: 'production',
        favorite: savedFavs.includes('mutage'),
        free: false,
      },
    };
  };

  const [boxettes, dispatchBoxettes] = React.useReducer(reducerBoxettes, initialBoxettes());

  function reducerBoxettes(currentState, dispatch) {
    const finalBoxettes = { ...currentState };
    const savedFavsLocal = localStorage.getItem('favorite_boxettes');
    const favoriteBoxettes = savedFavsLocal === null ? [] : JSON.parse(savedFavsLocal);
    switch (dispatch.type) {
      case 'addToFavorites':
        finalBoxettes[dispatch.name].favorite = true;
        favoriteBoxettes.push(dispatch.name);
        localStorage.setItem('favorite_boxettes', JSON.stringify(favoriteBoxettes));
        return finalBoxettes;
      case 'removeFromFavorites':
        finalBoxettes[dispatch.name].favorite = false;
        localStorage.setItem(
          'favorite_boxettes',
          JSON.stringify(favoriteBoxettes.filter((el) => el !== dispatch.name)),
        );
        return finalBoxettes;
      case 'clear':
        localStorage.setItem('favorite_boxettes', JSON.stringify([]));
        return [];
      default:
        throw new Error();
    }
  }

  const boxettesActivated = (disabled) => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === true
    && !disabled;

  const showEmailVerify = () => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === false;

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  const boxettesAvailable = hasRequiredGrants
    ? Object.keys(boxettes)
    : Object.keys(boxettes).filter((key) => boxettes[key].free === true);
  const boxettesNotAvailable = hasRequiredGrants
    ? []
    : Object.keys(boxettes).filter((key) => boxettes[key].free === false);

  return (
    <Box sx={{
      display: 'flex',
      flexFlow: 'column',
      gap: { xs: 1, sm: 2 },
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1500,
    }}
    >
      {showEmailVerify() && (
      <VerifyEmail />)}
      <FavoriteBoxettes boxettes={boxettes} dispatchBoxettes={dispatchBoxettes} />
      <Box sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        gap: { xs: 1, sm: 2 },
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
      }}
      >
        {boxettesAvailable.map((key) => (
          <BoxetteCard
            key={key}
            title={boxettes[key].title}
            image={boxettes[key].image}
            activated={boxettesActivated(boxettes[key].disabled)}
            route={boxettes[key].route}
            dispatchBoxettes={dispatchBoxettes}
            isFavorite={boxettes[key].favorite}
            free={boxettes[key].free}
          />
        ))}
      </Box>

      {boxettesNotAvailable.length > 0 && (
        <Box sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: { xs: 1, sm: 2 },
          justifyContent: 'center',
          alignItems: 'flex-start',
          border: 5,
          borderColor: '#FFD700',
          borderRadius: 2,
          py: 2,
          mt: 2,
          position: 'relative',
        }}
        >
          <Button
            variant="contained"
            size="large"
            color="gold"
            component={Link}
            to="/subscriptions"
            sx={{
              background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)', // Gold gradient
              border: 0,
              borderRadius: 2,
              boxShadow: '0 3px 5px 2px rgba(255, 215, 0, .3)', // Soft shadow
              color: 'white',
              height: 48,
              width: '80%',
              maxWidth: 200,
              padding: '0 30px',
              '&:hover': {
                background: 'linear-gradient(45deg, #FFD700 30%, #FF8C00 90%)', // Slightly darker on hover
              },
              position: 'absolute',
              top: '28px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontWeight: 'bold',
              zIndex: 2,
            }}
            endIcon={<LockOpenIcon />}
          >
            {t('unlock')}
          </Button>
          {boxettesNotAvailable.map((key) => (
            <BoxetteCard
              key={key}
              title={boxettes[key].title}
              image={boxettes[key].image}
              activated={boxettesActivated(boxettes[key].disabled)}
              route={boxettes[key].route}
              dispatchBoxettes={dispatchBoxettes}
              isFavorite={boxettes[key].favorite}
              free={boxettes[key].free}
            />
          ))}
        </Box>
      )}
      <OIMLConformity />
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlcoConversionTAVorMV from 'components/boxettes/alco-conversion/AlcoConversionTAVorMV';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import ForwardButton from '../alco-conversion/ForwardButton';

export default function VolumeConversionAlcoBoxette(props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.vol_conversion')}
      </Typography>
      <AlcoConversionTAVorMV actions={<BoxetteActions />} {...props} />
    </Box>
  );
}

function BoxetteActions(props) {
  const { t } = useTranslation();
  const {
    calculating, formikIsValid, formikSubmitForm, formikValues,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
      }}
    >
      <ForwardButton
        calculating={calculating}
        isValid={formikIsValid}
        text={t('forms.toward_volume_conversion')}
        creditsCost={formikValues.alcoholUnit === 'TAV'
          && formikValues.measuredTemp === '20.0'
          && formikValues.temperatureUnit === 'celsius' ? 0 : 1}
        handleClick={async () => {
          const results = await formikSubmitForm();
          if (formikIsValid && results !== undefined) {
            sessionStorage.removeItem('current_form:volume_conv_form');
            sessionStorage.removeItem('current_form:volume_pa_conv_form');
            navigate('../vol-conv', {
              state: {
                alcoholConversionResults: results,
                temperatureUnit: formikValues.temperatureUnit,
                fromVolTemp: formikValues.measuredTemp,
                ...location.state,
              },
            });
          }
        }}
      />
      <ForwardButton
        calculating={calculating}
        isValid={formikIsValid}
        text={t('forms.toward_pa_volume_conversion')}
        creditsCost={formikValues.alcoholUnit === 'TAV'
          && formikValues.measuredTemp === '20.0'
          && formikValues.temperatureUnit === 'celsius' ? 0 : 1}
        handleClick={async () => {
          const results = await formikSubmitForm();
          if (formikIsValid && results !== undefined) {
            sessionStorage.removeItem('current_form:volume_conv_form');
            sessionStorage.removeItem('current_form:volume_pa_conv_form');
            navigate('../vol-pa-conv', {
              state: {
                alcoholConversionResults: results,
                temperatureUnit: formikValues.temperatureUnit,
                temperatureValue: formikValues.measuredTemp,
                ...location.state,
              },
            });
          }
        }}
      />
    </Box>
  );
}

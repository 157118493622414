import React, { useEffect, useCallback } from 'react';
import {
  IconButton,
  Toolbar,
  Drawer,
  AppBar,
  Box,
  SwipeableDrawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  BrowserRouter,
  useLocation,
} from 'react-router-dom';
import TopToolbar from 'components/TopToolbar';
import { menuPaths } from 'components/utils';
import useGlobal from 'global-state/store';
import {
  doc, getDoc,
} from 'firebase/firestore';
import { useUser, useFirestore, useSigninCheck } from 'reactfire';
import { MenuProvider } from 'menu-actions/MenuContext';
import BackToHookButton from 'components/deeplinks/BackToHookButton';
import DrawerList from 'components/drawer-menu/DrawerList';
import DrawerRoutes from 'components/drawer-menu/DrawerRoutes';
import { Cookies } from 'labox-ws-commons';
import { useTranslation } from 'react-i18next';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import usePrevious from 'customHooks/usePrevious';
import Footer from './Footer';
import GlobalSnackbar from './GlobalSnackbar';
import ScrollToTop from './ScrollToTop';
import LoadingPage from './LoadingPage';
import { getAlcoholometryAccessGrants } from './subscriptions/utils';
import SEO from './SEO';

const drawerWidth = 219;

export default function Home() {
  return <RemoteConfigStatus defaultComponent={<MenuProvider app={<ResponsiveDrawer />} />} />;
}

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const db = useFirestore();
  const { data: user } = useUser();
  const { data: signInCheckResult } = useSigninCheck();
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const prevUserStatus = usePrevious(globalState.userStatus);

  const shouldLoad = useCallback(
    () => (prevUserStatus === 'initial' && globalState.userStatus === 'initial')
    || (prevUserStatus === 'setupDone' && globalState.userStatus === 'initial'),
    [globalState.userStatus, prevUserStatus],
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const getUser = useCallback(async (uid) => {
    const userRef = doc(db, 'users', uid);
    const firestoreUser = await getDoc(userRef);
    return firestoreUser;
  }, [db]);

  const getAlcoholometryAccessGrantsCb = useCallback(async (activeOrganization) => getAlcoholometryAccessGrants(
    db,
    user,
    activeOrganization,
  ), [db, user]);

  const getUserPrivateData = useCallback(async (uid) => {
    const userRef = doc(db, 'users', uid, 'private', 'data');
    const firestoreUser = await getDoc(userRef);
    return firestoreUser;
  }, [db]);

  const fetchMultipleTimesUser = useCallback(async (uid) => {
    let firestoreUser = await getUser(uid);
    if (!firestoreUser.exists()) {
      await sleep(1000);
      firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(3000);
        firestoreUser = await getUser(uid);
      }
    }
    return firestoreUser;
  }, [getUser]);

  const setupGlobalStateFromUser = useCallback(async () => {
    const firestoreUser = await fetchMultipleTimesUser(user.uid);
    const userData = firestoreUser.data();
    if (userData) {
      if (userData.laboxWSRoles) {
        globalActions.setUserRoles(userData.laboxWSRoles);
      }
      if (userData.issuers) {
        globalActions.setUserIssuers(userData.issuers);
      }
      const firestoreUserPrivateData = await getUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      globalActions.setActiveOrganization(userPrivateData.activeOrganization);
      const userOrganizations = userPrivateData.organizations.reduce((obj, id) => ({
        ...obj,
        [id]: undefined,
      }), {});
      globalActions.setUserOrganizations(userOrganizations);
      const accessGrants = await getAlcoholometryAccessGrantsCb(userPrivateData.activeOrganization);
      globalActions.setAccessGrants(accessGrants.validGrants);
      globalActions.setAccessGrantNames(accessGrants.uniqueGrantNames);
    }
    return globalActions.setUserStatus('setupDone');
  }, [fetchMultipleTimesUser, getAlcoholometryAccessGrantsCb,
    getUserPrivateData, globalActions, user?.uid]);

  useEffect(() => {
    if (shouldLoad() && signInCheckResult?.signedIn) {
      globalActions.setUserStatus('loading');
      setupGlobalStateFromUser();
    } else if (shouldLoad() && signInCheckResult?.signedIn === false) {
      globalActions.setUserStatus('loading');
      globalActions.setUserStatus('setupDone');
    }
  }, [globalActions, setupGlobalStateFromUser, shouldLoad, signInCheckResult?.signedIn]);

  if (globalState.userStatus !== 'setupDone') {
    return (
      <LoadingPage />
    );
  }

  return (
    <BrowserRouter>
      <Box>
        <SEO />
        <ScrollToTop />
        <GlobalSnackbar />
        <AppBar
          position="fixed"
          elevation={1}
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
          }}
          color="inherit"
        >
          <Toolbar>
            <MenuButton
              handleDrawerOpenClose={handleDrawerToggle}
            />
            <TopToolbar />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ flexShrink: { md: 0 } }}
        >
          <Drawer
            open={mobileOpen}
            container={container}
            variant="temporary"
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <DrawerList />
          </Drawer>
          <SwipeableDrawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
          >
            <DrawerList />
          </SwipeableDrawer>
        </Box>
        <Box
          component="main"
          sx={{
            ml: { md: `${drawerWidth}px` },
            px: { xs: 1, sm: 2, md: 3 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{
            minHeight: '75vh',
          }}
          >
            <Toolbar sx={{ mb: 2 }} />
            <DrawerRoutes />
          </Box>
          <BackToHookButton />
          <Footer />
          <Cookies
            cookieName="agreed-to-cookies-on-labox-alcoholometry"
            t={t}
            globalActions={globalActions}
          />
        </Box>
      </Box>
    </BrowserRouter>
  );
}

function MenuButton(props) {
  const location = useLocation();
  const { handleDrawerOpenClose } = props;
  if (menuPaths(location)) {
    return (
      <IconButton
        edge="start"
        sx={{ mr: 2, display: { md: 'none' } }}
        color="inherit"
        aria-label="menu"
        onClick={() => handleDrawerOpenClose()}
      >
        <MenuIcon />
      </IconButton>
    );
  }
  return null;
}
